import React, { Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import MapContainer from "../maps/MapContainer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetailTooltip from "../layout/DetailTooltip";
import { createCompany, getCurrentCompany } from "../../actions/company";
import { Helmet } from "react-helmet";
import Select from "react-select";
import CreateServices from "./CreateServices";
import { useGoogleLogin } from "react-google-login";
import AutoComplete from "../maps/Autocomplete";
import { getCurrentCompanyList } from "../../actions/company";
const initialState = {
  name: "",
  website: "",
  description: "",
  street_address: "",
  street_address_2: "",
  city: "",
  province: "",
  country: "",
  postal: "",
  email: "",
  phone: "",
  fax: "",
  lat: "",
  lng: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
  Sunday_open: true,
  Sunday_start_time: 540,
  Sunday_end_time: 1020,
  Monday_open: true,
  Monday_start_time: 540,
  Monday_end_time: 1020,
  Tuesday_open: true,
  Tuesday_start_time: 540,
  Tuesday_end_time: 1020,
  Wednesday_open: true,
  Wednesday_start_time: 540,
  Wednesday_end_time: 1020,
  Thursday_open: true,
  Thursday_start_time: 540,
  Thursday_end_time: 1020,
  Friday_open: true,
  Friday_start_time: 540,
  Friday_end_time: 1020,
  Saturday_open: true,
  Saturday_start_time: 540,
  Saturday_end_time: 1020,
  markers: [],
  geoLocation: "",
  category: "",
  subcategory: "",
  calendartype: "",
  calendartype: "",
};

const CreateUserBusiness = ({
  company: { company },
  createCompany,
  history,
  categoryList,
  setShow,
  getCompanyList,
}) => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [calenderEmail, setCalendarEmail] = useState(null);
  const [facebookError, setfacebookError] = useState("");
  const [linkedinError, setlinkedinError] = useState("");
  const [instagramError, setinstagramError] = useState("");
  const [services, setServices] = useState([]);
  const [showCalandly, setShowCalandly] = useState(false);
  const [showJaneApp, setShowJaneApp] = useState(false);
  const [isSynced, setIsSynced] = useState(false);
  const [serviceData, setserviceData] = useState({
    name: "",
    description: "",
    service_duration: 15,
    price: "",
    book_online: false,
    book_site: false,
    book_site_link: "",
    call_to_book: true,
    intakeform: [],
  });
  const [isSelected, setIsSelected] = useState({
    category: false,
    subcategory: false,
    location: false,
  });
  const [weekDay, setWeekDay] = useState([0, 1, 2, 3, 4, 5, 6]);
  const closeRef = useRef(null);
  const d_names = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  console.log("formData", formData);
  const {
    name,
    website,
    description,
    street_address,
    street_address_2,
    city,
    province,
    country,
    postal,
    email,
    phone,
    fax,
    lat,
    lng,
    facebook,
    instagram,
    linkedin,
    /*Sunday_open,
    Sunday_start_time,
    Sunday_end_time,
    Monday_open,
    Monday_start_time,
    Monday_end_time,
    Tuesday_open,
    Tuesday_start_time,
    Tuesday_end_time,
    Wednesday_open,
    Wednesday_start_time,
    Wednesday_end_time,
    Thursday__open,
    Thursday_start_time,
    Thursday_end_time,
    Friday_open,
    Friday_start_time,
    Friday_end_time,
    Saturday_open,
    Saturday_start_time,
    Saturday_end_time,*/
    markers,
  } = formData;
  let tempData;
  const [addressArray, setAddressArray] = useState([]);
  const categoryRef = useRef(null);
  const locationRef = useRef(null);
  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };
  const timeDropDownOptions = timeDropDown();

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }
  function clearPhoneNumber(value = "") {
    return (value = value.replace(/\D/g, ""));
    // return (value = value.replace(/^(\d{2})(\d{3})(\d{4})$/, "($1)-$2-$3"));
  }
  const onChange = (e, lat) => {
    console.log("e.target.name", e.target.name);
    if (e.target.name === "fax") {
      e.target.value = clearNumber(e.target.value);
    }

    if (e.target.name === "facebook") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setfacebookError("");
      } else {
        setfacebookError("This is not a valid facebook link.");
      }
    }

    if (e.target.name === "linkedin") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setlinkedinError("");
      } else {
        setlinkedinError("This is not a valid linkedin link.");
      }
    }

    if (e.target.name === "instagram") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setinstagramError("");
      } else {
        setinstagramError("This is not a valid instagram link.");
      }
    }
    if (e.target.name === "book_site_link") {
      setserviceData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value
      }));
  }

  // add in any missing service data

    if (e.target.name === "name") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      // let reference = /^[ A-Za-z0-9 & #%()\[\]+\-'é®ã@:|]*$/;
      // if (reference.test(e.target.value) || e.target.value === "") {
      //   setFormData({ ...formData, [e.target.name]: e.target.value });
      // }

      // }else{
      //   setFormData({ ...formData, [e.target.name]: e.target.value });
      // }
    } else if (e.target.name === "phone") {
      setFormData({
        ...formData,
        [e.target.name]: clearPhoneNumber(e.target.value),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (lat != null) {
      setFormData({ ...formData, lat: lat });
    }
  };

  const addressUpdate = (address, latitiude, lng) => {
    tempData = { ...formData };
    setIsSelected({
      ...isSelected,
      location: false,
    });
    address.length > 0 &&
      address.map((add, i) =>
        add.types[0] == "route"
          ? ((addressArray["street_address"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "neighborhood"
          ? ((addressArray["street_address_2"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "locality"
          ? ((addressArray["street_address_2"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "administrative_area_level_2"
          ? ((addressArray["city"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "administrative_area_level_1"
          ? ((addressArray["province"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "country"
          ? ((addressArray["country"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "postal_code_prefix"
          ? ((addressArray["postal"] = add.long_name),
            setAddressArray([...addressArray]))
          : ""
      );

    setFormData({
      ...formData,
      street_address: addressArray.street_address
        ? addressArray.street_address
        : "",
      street_address_2: addressArray.street_address_2
        ? addressArray.street_address_2
        : "",
      city: addressArray.city ? addressArray.city : "",
      province: addressArray.province ? addressArray.province : "",
      country: addressArray.country ? addressArray.country : "",
      postal: addressArray.postal ? addressArray.postal : "",
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // if (
    //   !formData.category ||
    //   !formData.subcategory ||
    //   !formData.lat ||
    //   !formData.lng
    // ) {
    //   formData.lat === "" &&
    //     formData.lng === "" &&
    //     locationRef.current.scrollIntoView();
    //   !formData.subcategory &&
    //     !formData.category &&
    //     categoryRef.current.scrollIntoView();
    //   setIsSelected({
    //     ...isSelected,
    //     category: !formData.category ? true : false,
    //     subcategory: !formData.subcategory ? true : false,
    //     location: !formData.lat || !formData.lng ? true : false,
    //   });
    // }

    const newService = serviceData.name &&
      serviceData.price && [...services, serviceData];
    console.log("formData", formData);
    const submitFormData = { ...formData, services: newService };

    // if (
    //   !facebookError &&
    //   !instagramError &&
    //   !linkedinError &&
    //   formData.subcategory &&
    //   formData.category &&
    //   formData.lat &&
    //   formData.lng
    // ) {
    console.log("submitFormData", submitFormData);
    createCompany(submitFormData, history, false);
    getCompanyList();
    await getCurrentCompanyList(0, "");
    setShow(false);
    // }
  };
  console.log("weekDay", weekDay);
  const handleToggle = (i) => {
    console.log("i", i);
    if (weekDay.includes(i)) {
      setWeekDay(weekDay.filter((elem) => elem !== i));
    } else {
      setWeekDay([...weekDay, i]);
    }
  };

  const onCategoryChange = (values) => {
    let dropdownfield = [{ label: "Subcategory", value: "" }];

    categoryList?.map((element) => {
      if (element.category_name === values) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });

    setSubCategories(dropdownfield);
    setFormData({
      ...formData,
      category: values,
      subcategory: "",
    });
  };

  let subcategoryValue, filterValue;
  filterValue = categories.filter((i) => i.value === formData?.category);
  if (formData.category) {
    subcategoryValue = subCategories?.filter(
      (i) => i.value === formData?.subcategory
    );
  }
  const clientId = process.env.REACT_APP_GOOGLECLIENTID;
  const onSuccess = (res) => {
    setFormData({
      ...formData,
      googleAuthBusiness: res.tokenObj,
      googleRefreshTokenBusiness: "",
      googleUserBusiness: {
        name: res.profileObj.name,
        email: res.profileObj.email,
      },
      calendartype: "",
      calendarurl: "",
    });
    setShowCalandly(false);
    setShowJaneApp(false);
    setIsSynced(true);
    setCalendarEmail(res?.profileObj?.email);
    closeRef.current.click();
  };
  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    issignedIn: true,
    accessType: "offline",
    scope:
      "profile email https://www.googleapis.com/auth/admin.directory.resource.calendar",
  });

  useEffect(() => {
    let CategoryList = [{ label: "Category", value: "" }];

    categoryList?.map((element) => {
      CategoryList.push({
        label: element.category_name,
        value: element.category_name,
      });
    });

    setCategories(CategoryList);

    filterValue = categories.filter((i) => i.value === formData?.category);
    if (formData.category) {
      subcategoryValue = subCategories?.filter(
        (i) => i.value === formData?.subcategory
      );
    }
  }, [formData, categoryList]);
  const onDisconnect = () => {
    setFormData({
      ...formData,
      googleAuthBusiness: null,
      googleRefreshTokenBusiness: "",
      googleUserBusiness: null,
    });
    setCalendarEmail(null);
    setIsSynced(false);
  };

  const addPlace = (place) => {
    const addressArray = {};
    place.address_components.length > 0 &&
      place.address_components.forEach((add) => {
        if (add.types[0] == "street_number" || add.types[0] == "route") {
          addressArray["street_address"] =
            (addressArray["street_address"] || "") + " " + add.long_name;
        } else if (add.types[0] == "locality") {
          addressArray["city"] = add.long_name;
        } else if (
          add.types[0] == "administrative_area_level_2" &&
          !addressArray["city"]
        ) {
          addressArray["city"] = add.long_name;
        } else if (add.types[0] == "administrative_area_level_1") {
          addressArray["province"] = add.long_name;
        } else if (add.types[0] == "country") {
          addressArray["country"] = add.long_name;
        } else if (add.types[0] == "postal_code_prefix") {
          addressArray["postal"] = add.long_name;
        }
      });
    setFormData({
      ...formData,
      street_address: addressArray.street_address
        ? addressArray.street_address
        : "",
      street_address_2: addressArray.street_address_2
        ? addressArray.street_address_2
        : "",
      city: addressArray.city ? addressArray.city : "",
      province: addressArray.province ? addressArray.province : "",
      country: addressArray.country ? addressArray.country : "",
      postal: addressArray.postal ? addressArray.postal : "",
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    });
  };

  return (
    <div>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>

      <div className="edit-service">
        {/* <button>
          <i
            className="fas fa-arrow-left"
            onClick={() => history.push("/admin")}
          ></i>
        </button> */}

        <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mt-2 mb-4">
          Add Business
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <section className="white-bg header-bg ">
          <div className="text-center d-flex justify-content-center align-items-center">
            <div className="buisness-information-edit row">
              <div className="col-md-6">
                <p className="font-semibold text-m m-0 text-center text-md-left">
                  Information
                </p>

                <div className="form-row form-group bg-lightpeach p-10 border-20">
                  <div className="col-md-12 px-0 pb-10">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="name"
                      placeholder="Company Name"
                      id="company-name"
                      maxLength="50"
                      value={name}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 pl-0 pb-10 pr-0 pr-md-1">
                    <input
                      type="email"
                      className="form-control font-medium text-medium"
                      name="email"
                      placeholder="Company Email"
                      id="company-email"
                      oninvalid="this.setCustomValidity('Please enter email address.')"
                      value={email}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 pr-0 pl-0 pl-md-1 pb-10">
                    <input
                      type="tel"
                      className="form-control font-medium text-medium"
                      name="phone"
                      placeholder="Business Phone Number"
                      id="company-phone"
                      pattern=".{0}|.{10,}"
                      // maxLength="10"
                      value={phone}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-md-12 px-0 pb-10">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="website"
                      placeholder="Website"
                      id="company-website"
                      value={website}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-12 px-0 pb-10 ">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="description"
                      placeholder="Brief Description / tagline"
                      id="company-description"
                      value={description}
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-md-6 d-none">
                    <input
                      type="tel"
                      className="form-control font-medium text-medium"
                      name="fax"
                      minLength="10"
                      maxLength="10"
                      placeholder="Fax Number"
                      id="company-fax"
                      value={fax}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-heading d-none">
                    <span>Social Media Links </span>
                  </div>

                  <div className="col-md-6 d-none">
                    <input
                      type="text"
                      className="form-control"
                      name="facebook"
                      placeholder="facebook.com/"
                      id="company-fax"
                      //   defaultValue={company?.social?.facebook}
                      onChange={onChange}
                    />
                    <p style={{ color: "red" }}>
                      {facebookError ? facebookError : ""}
                    </p>
                  </div>
                  <div className="col-md-6 d-none">
                    <input
                      type="text"
                      className="form-control"
                      name="linkedin"
                      placeholder="linkedin.com/in/"
                      id="company-fax"
                      //   defaultValue={company?.social?.linkedin}
                      onChange={onChange}
                    />
                    <p style={{ color: "red" }}>
                      {linkedinError ? linkedinError : ""}
                    </p>
                  </div>
                  <div className="col-md-6 d-none">
                    <input
                      type="tel"
                      className="form-control"
                      name="instagram"
                      placeholder="instagram.com/"
                      id="company-fax"
                      onChange={onChange}
                    />
                    <p style={{ color: "red" }}>
                      {instagramError ? instagramError : ""}
                    </p>
                  </div>

                  <div
                    className="col-md-12  row px-0 m-0 pb-10"
                    ref={categoryRef}
                  >
                    <div className="col-md-6 pr-0 pr-md-1 pl-0">
                      <Select
                        options={categories}
                        className="field-type mb-0 px-0 font-medium text-medium"
                        styles={{
                          menu: (base) => ({ ...base, zIndex: 99999999 }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "8px",
                          }),
                        }}
                        name="category"
                        defaultValue={filterValue && filterValue[0]}
                        value={filterValue && filterValue[0]}
                        onChange={(value) => {
                          onCategoryChange(value.value);
                          setIsSelected({
                            ...isSelected,
                            category: false,
                          });
                        }}
                      />
                      {isSelected.category && (
                        <label className="text-danger d-flex">
                          Please select category.
                        </label>
                      )}
                    </div>
                    {subCategories?.length > 0 && (
                      <div className="col-md-6 pr-0 pl-0 pl-md-1 ">
                        <Select
                          options={subCategories}
                          className="field-type mb-0 font-medium text-medium"
                          styles={{
                            menu: (base) => ({ ...base, zIndex: 99999999 }),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "8px",
                            }),
                          }}
                          defaultValue={subcategoryValue && subcategoryValue[0]}
                          value={subcategoryValue && subcategoryValue[0]}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              subcategory: value.value,
                            });
                            setIsSelected({
                              ...isSelected,
                              subcategory: false,
                            });
                          }}
                        />
                        {isSelected.subcategory && (
                          <label className="text-danger d-flex">
                            Please select subcategory.
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 row flex-column gap-10 mx-auto px-0">
                    <input
                      type="button"
                      className="btn create-service-btn text-white bg-orange border-0 font-medium"
                      value="Google Calendar"
                      data-tut="register_button"
                      onClick={signIn}
                      disabled={calenderEmail}
                    />
                    {(isSynced || calenderEmail) && (
                      <div className="form-control plus-sign calendar-email-box h-auto">
                        <p className="m-0">{calenderEmail}</p>
                        <div className="plusbtnclass">
                          <i
                            className="fa-solid fa-close calender_icon"
                            style={{
                              fontSize: "15px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={onDisconnect}
                          ></i>
                        </div>
                      </div>
                    )}

                    <input
                      type="button"
                      className="btn create-service-btn text-white bg-orange border-0 font-medium"
                      value="Calendly"
                      data-tut="register_button"
                      onClick={() => {
                        onDisconnect();
                        setShowCalandly(true);
                        setShowJaneApp(false);
                        setFormData({
                          ...formData,
                          calendartype: "",
                          calendarurl: "",
                        });
                      }}
                    />

                    {(showCalandly ||
                      formData?.calendartype === "calandly") && (
                      <input
                        type="url"
                        className="form-control"
                        placeholder="Enter Calendly URL"
                        value={formData.calendarurl}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            calendartype: "calandly",
                            calendarurl: e.target.value,
                          })
                        }
                      />
                    )}

                    <input
                      type="button"
                      className="btn create-service-btn text-white bg-orange border-0 font-medium"
                      value="JaneApp"
                      data-tut="register_button"
                      onClick={() => {
                        onDisconnect();
                        setShowJaneApp(true);
                        setShowCalandly(false);
                        setFormData({
                          ...formData,
                          calendartype: "",
                          calendarurl: "",
                        });
                      }}
                    />

                    {(showJaneApp || formData?.calendartype === "janeapp") && (
                      <input
                        type="url"
                        className="form-control"
                        placeholder="Enter JaneApp URL"
                        value={formData.calendarurl}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            calendartype: "janeapp",
                            calendarurl: e.target.value,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="form-heading d-none" ref={locationRef}>
                    <span>Location*</span>
                  </div>
                  <div className="map-location d-none">
                    <MapContainer
                      mapMarkers={markers}
                      newMarker={false}
                      currentLat={formData.lat}
                      currentLng={formData.lng}
                      addressupdate={addressUpdate}
                      onChange={onChange}
                    />
                    {isSelected.location && (
                      <label className="text-danger d-flex">
                        Please select valid location.
                      </label>
                    )}
                  </div>

                  <input
                    type="hidden"
                    id="company-lat"
                    value={lat}
                    name="lat"
                    onChange={onChange}
                  />
                  <input
                    type="hidden"
                    id="company-lng"
                    value={lng}
                    name="lng"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <p className="font-semibold text-m m-0 text-center text-md-left pb-10">Address</p>
                <div className="form-row form-group bg-lightpeach p-10 border-20 mb-0">
                  {/* <div className="col-md-6 pl-0 pr-1 pb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="street_address"
                      placeholder="Street Address "
                      maxLength="50"
                      id="company-street_address"
                      value={street_address}
                      onChange={onChange}
                    />
                  </div> */}
                  <div className="col-md-6 business-registration px-0 "
                   style={{ marginBottom: "38px" }}
                   >
                    <AutoComplete
                      addPlace={addPlace}
                      isFilter={false}
                      placeholder="Street Address"
                      className="font-medium text-medium"
                    />
                    {/* <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="Street Address "
                        name="street_address"
                        value={street_address}
                        onChange={onChange}
                        required
                      /> */}
                  </div>
                  <div className="col-md-6 pl-0 pl-md-1 pr-0 mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="street_address_2"
                      placeholder="Street Address 2"
                      maxLength="50"
                      id="company-street_address_2"
                      value={street_address_2}
                      onChange={onChange}
                    />
                  </div>
                  <div className="mt-2 mt-md-2 col-md-6 pl-0 pr-0 pr-md-1">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="city"
                      placeholder="City "
                      id="company-city"
                      maxLength="50"
                      value={city}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 pl-0 pl-md-1 pr-0 py-0 mt-2 mt-md-2">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="province"
                      placeholder="Province"
                      maxLength="50"
                      id="company-province"
                      value={province}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 pl-0 pr-0 pr-md-1 mt-2 mt-md-2">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="country"
                      placeholder="Company Country"
                      id="company-country"
                      maxLength="50"
                      value={country}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 pl-0 pl-md-1 pr-0 mt-2 mt-md-2">
                    <input
                      type="text"
                      className="form-control font-medium text-medium"
                      name="postal"
                      placeholder="Postal Code"
                      id="company-postal"
                      maxLength="50"
                      value={postal}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <p className="font-semibold text-m m-0 text-center text-md-left mt-4 pb-10">
                  Services
                </p>
                <CreateServices
                  onChange={onChange}
                  setserviceData={setserviceData}
                  serviceData={serviceData}
                  formData={formData}
                  setServices={setServices}
                  clearNumber={clearNumber}
                  services={services}
                />
              </div>
              <div className="col-6 col-12 col-md-6">
                <p
                  className="font-semibold text-m m-0 text-center text-md-left mt-4 pb-10"
                  style={{
                    marginBottom: "3px",
                    marginLeft: "6px",
                  }}
                >
                  Hours of Operation
                </p>

              <div className="form-row form-group bg-lightpeach p-10 border-20">
    {d_names.map((day, i) => {
      return (
        <div className="hours-grid-container" key={i}>
          <div className="hours-grid-container-section-1">
            <label
              className="font-semibold text-medium"
              style={{
                color: "#4E4E4E",
                marginLeft: "2px",
              }}
            >
              {day}
            </label>
            <div
              className="hours-grid-container-switch-container"
              style={{
                display: "flex",
                alignItems: "baseline",
                paddingRight: "20px",
                width: "50%",
              }}
            >
              <label className="switch hours-grid-container-switch">
                <input
                  type="checkbox"
                  name={`${day}_open`}
                  id={`${day}_open`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [day + "_open"]: !formData[day + "_open"],
                    });
                  }}
                  onClick={() => handleToggle(i)}
                  checked={formData[`${day}_open`]}
                />
                <span className="slider round"></span>
              </label>
              <span
                className="font-medium text-medium hours-grid-container-switch-text"
                style={{
                  color: "#4E4E4E",
                }}
              >
                {formData[`${day}_open`] ? "Open" : "Closed"}
              </span>
            </div>
          </div>
          <div className="hours-grid-container-section-2 mb-2 mb-md-0">
            <div
              className="grid-col"
              style={{
                width: "100%",
              }}
            >
              <div className="grid-item">
                <select
                  className="form-control font-medium text-medium"
                  style={{
                    color: "#4E4E4E",
                  }}
                  name={`${day}_start_time`}
                  placeholder="Closes at"
                  onChange={onChange}
                  value={formData[day + "_start_time"]}
                >
                  {timeDropDownOptions.map((time_option, i) => (
                    <Fragment key={i}>{time_option}</Fragment>
                  ))}
                </select>
              </div>

              <div className="grid-item closing-time-dropdown-container">
                <select
                  className="form-control font-medium text-medium"
                  style={{
                    color: "#4E4E4E",
                  }}
                  name={`${day}_end_time`}
                  placeholder="Closes at"
                  onChange={onChange}
                  value={formData[day + "_end_time"]}
                >
                  {timeDropDownOptions
                    .filter(
                      (time_option) =>
                        parseInt(time_option.props.value) >
                        parseInt(formData[day + "_start_time"])
                    )
                    .map((time_option, i) => (
                      <Fragment key={i}>{time_option}</Fragment>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>
              </div>

              {/* <div className="buisness-hours add-business-hours d-none">
                <h4 className="logintitle">
                  Business Hours*
                  <DetailTooltip text="Set Business hours , for your company" />
                </h4>

                {d_names.map((day, i) => {
                  return (
                    <div className="grid-container" key={i}>
                      <label>{day}</label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name={`${day}_open`}
                          id={`${day}_open`}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [day + "_open"]: !formData[day + "_open"],
                            });
                          }}
                          onClick={() => handleToggle(i)}
                          checked={weekDay.includes(i)}
                        />
                        <span className="slider round"></span>
                      </label>
                      {weekDay.includes(i) && (
                        <div className="grid-col">
                          <div className="grid-item">
                            <select
                              className="form-control font-medium text-medium"
                              name={`${day}_start_time`}
                              placeholder="Closes at"
                              onChange={onChange}
                              value={formData[day + "_start_time"]}
                            >
                              {timeDropDownOptions.map((time_option, i) => (
                                <Fragment key={i}>{time_option}</Fragment>
                              ))}
                            </select>
                          </div>

                          <div className="grid-item">
                            <select
                              className="form-control font-medium text-medium"
                              name={`${day}_end_time`}
                              placeholder="Closes at"
                              onChange={onChange}
                              value={formData[day + "_end_time"]}
                            >
                              {timeDropDownOptions.map((time_option, i) => (
                                <Fragment key={i}>{time_option}</Fragment>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
          <div className="text-center w-100 mt-2">
            <button
              type="submit"
              className={`btn btn-primary text-xs font-semibold w-25 mt-1 mb-5 ${
                !formData.name ||
                !formData.phone ||
                !formData.email ||
                !formData.phone ||
                (!formData.country && !formData.province && !formData.city)
                  ? "disabled-btn border-0"
                  : ""
              }`}
              disabled={
                !formData.name ||
                !formData.phone ||
                !formData.email ||
                !formData.phone ||
                (!formData.country && !formData.province && !formData.city)
              }
            >
              Save New Business
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

CreateUserBusiness.propTypes = {
  createCompany: PropTypes.func.isRequired,
  getCurrentCompany: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  // markers: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  formfields: state.formfields,
  categoryList: state.company.category,
  // markers: state.markers
});

export default connect(mapStateToProps, {
  createCompany,
  getCurrentCompany,
})(withRouter(CreateUserBusiness)); // need withRouter to pass history object
